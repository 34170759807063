/**
 * 津波情報パネル
 * @module app/monitor/TsunamiPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/promise/all',
    'dojo/text!./templates/TsunamiPanel.html',
    'dojo/topic',
    'dojo/Deferred',
    'dojo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, domConstruct, JSON, all, template, topic, Deferred,
    dojo, Router, UserInfo, Requester, DialogChain, Loader, _PageBase) {
    /**
     * 津波情報パネル
     * @class TsunamiPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/TsunamiPanel~TsunamiPanel# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 最大表示件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = UserInfo.getSelectedMunicipalityCd();
            console.debug('津波情報表示対象の市町村コード：' + this._municipalityCd);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に気象情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));
        },


        /**
         * テーブルを初期化します
         */
        initTable: function() {
            // 津波情報を設定
            this.setTsunamiListInfo();
        },

        /**
         * 津波情報を設定します
         */
        setTsunamiListInfo: function() {
            var self = this;
            self.tsunamiDataList = [];
            all({
                // 津波情報
                info: this.getTsunamiInfo(),
                // 津波警報・注意報
                alert: this.getTsunamiAlert()
            }).then(lang.hitch(this, function(result) {
                if (result.info && result.info.items.length > 0) {
                    self.tsunamiDataList.push(result.info);
                }
                if (result.alert && result.alert.items.length > 0) {
                    self.tsunamiDataList.push(result.alert);
                }
                // 津波情報を設定
                self.setTsunamiList(self, self.tsunamiDataList);
            }));

        },

        /**
         * 津波情報を取得します
         */
        getTsunamiInfo: function() {
            var deferred = new Deferred();
            Requester.get('/data/tsunami/info/tsunamiInfo.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                deferred.resolve(data);
            }, function(error) {
                if (error.response.status === 404) {
                    var item = {items:[]};
                    deferred.resolve(item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            return deferred.promise;
        },

        /**
         * 津波警報・注意報を取得します
         */
        getTsunamiAlert: function() {
            var deferred = new Deferred();
            Requester.get('/data/tsunami/alert/tsunamiAlert.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                deferred.resolve(data);
            }, function(error) {
                if (error.response.status === 404) {
                    var item = {items:[]};
                    deferred.resolve(item);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            });
            return deferred.promise;
        },

        /**
         * 津波を設定します
         */
        setTsunamiList: function(self, dataList) {
            var tsunamiPanelListTbody = dojo.byId('tsunamiPanelListTbody');
            domConstruct.empty(tsunamiPanelListTbody);

            var html = '';
            if (dataList.length === 0) {
                html += '<tr>';
                html += '<td colspan="5" style="text-align: left;">現在、津波情報はありません。</td>';
                html += '</tr>';
            } else {

                var tsunamiList = {'items': []};
                var currentDateTime = new Date().getTime();
                var prefAreaNames = ['讃岐阿波沿岸', '鳴門海岸', '北灘地区海岸', '鳥ケ丸地先海岸'];

                array.forEach(dataList, function (data) {
                    array.forEach(data.items, function(item){
                        var infoKind = item.infokind;
                        var reportDatetime = new Date(item.reportDatetime.replace(/-/g, '/')).getTime();
                        // 30日以内のものだけを対象とする。
                        if (reportDatetime + (30*24*60*60*1000) < currentDateTime) {
                            return;
                        }
                        // 対象の予報区ごとに抽出
                        array.forEach(prefAreaNames, function(area){
                            var filtered = item.regions.filter(function(element) {
                                return (area === element.areaName);
                            });
                            if (filtered.length === 0) {
                                return;
                            }
                            // 津波情報の場合に同じ予報区で複数のデータがある（観測所単位）ため、どれか1つを選ぶ
                            // ※警報・注意報の場合は予報区でデータが1つのみであるため、結果としてそのまま選ばれる
                            // 到達推定時刻の降順(nullを考慮) > 最大高さの降順、の順にソート
                            var sortedAreaItems = filtered.sort(function(a,b){
                                if (a.firstheightArrivaltime !== null && b.firstheightArrivaltime === null) {
                                    return -1;
                                } else if (a.firstheightArrivaltime === null && b.firstheightArrivaltime !== null) {
                                    return 1;
                                } else if(a.firstheightArrivaltime > b.firstheightArrivaltime || 
                                    (a.firstheightArrivaltime === b.firstheightArrivaltime && a.foMaxheightTsunamiheight > b.foMaxheightTsunamiheight)){
                                    return -1;    
                                }else if(a.foMaxheightTsunamiheight === b.foMaxheightTsunamiheight){
                                    return 0;
                                }else{
                                    return 1;
                                }
                            });
                            var selectedItem = sortedAreaItems[0];
                            tsunamiList.items.push({
                                'reportDatetime' :	reportDatetime,
                                'areaName' :	selectedItem.areaName,
                                'kindName' :	selectedItem.kindName || infoKind,
                                'foMaxheightTsunamiheight' : selectedItem.foMaxheightTsunamiheightDescription,
                                'firstheightArrivaltime' : selectedItem.firstheightArrivaltime,
                                'firstheightCondition' : selectedItem.firstheightCondition
                            });
                        });
                    });
                });

                // 発表日時の降順 > 到達推定時刻の降順、の順にソート
                var sortedItems = tsunamiList.items.sort(function(a,b){
                    if(a.reportDatetime > b.reportDatetime || 
                        (a.reportDatetime === b.reportDatetime && a.firstheightArrivaltime > b.firstheightArrivaltime)){
                        return -1;    
                    }else if(a.firstheightArrivaltime === b.firstheightArrivaltime){
                        return 0;
                    }else{
                        return 1;
                    }
                });

                if (sortedItems.length === 0) {
                    html += '<tr>';
                    html += '<td colspan="5" style="text-align: left;">現在、津波情報はありません。</td>';
                    html += '</tr>';
                } else {
                    // 先頭5件のみ表示
                    if (sortedItems.length > this.MAX_COUNT) {
                        sortedItems.length = this.MAX_COUNT;
                    }
                    array.forEach(sortedItems, function(item){
                        var datetimeString1 = self.formatDateTime(item.reportDatetime);
                        var datetimeString2 = '';
                        if (item.firstheightArrivaltime) {
                            datetimeString2 = self.formatDateTime(item.firstheightArrivaltime);
                        } else {
                            datetimeString2 = item.firstheightCondition || '';
                        }
                        html += '<tr>';
                        html += '<td>' + datetimeString1 + '</td>';
                        html += '<td>' + item.areaName + '</td>';
                        html += '<td>' + item.kindName + '</td>';
                        html += '<td>' + (item.foMaxheightTsunamiheight ? item.foMaxheightTsunamiheight : '') + '</td>';
                        html += '<td>' + datetimeString2 + '</td>';
                        html += '</tr>';
                    });
                }
            }

            var dom = domConstruct.toDom(html);
            domConstruct.place(dom, tsunamiPanelListTbody);
        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy/MM/dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH:mm'
            });
            return dateLabel + '<br>' + timeLabel;
        },

        /**
         * 津波情報一覧ページに遷移
         */
        onTsunamiLinkClick: function(evt){
            evt.preventDefault();
            Router.moveTo('tsunami');
        }

    });
});
