/**
 * チャットグループ更新用ダイアログモジュール。
 * @module app/chat/ChatGroupDetailDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/text!./templates/ChatGroupDetailDialog.html',
    './_ChatGroupDialogBase',
    'app/config',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'app/view/form/DisasterPreventionMunicOrgSelector',
    'idis/view/form/WordCountTextarea'
], function(module, leaflet, declare, lang, domStyle, on, popup, TooltipDialog, template, _ChatGroupDialogBase,
    config, ACL, UserInfo, Requester) {
    
    return declare(module.id.replace(/\//g, '.'), _ChatGroupDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            // ツールチップをセット
            this.setAvailableTips();
        },

        // バージョン
        _version: null,

        /**
         * フォームを初期化する。
         */
        initForm: function (object) {
            this._chatGroupId = object.chatGroupId;
            var self = this;
            var page = this.form;
            Requester.get('/api/chat/group/' + this._chatGroupId)
            .then(function(data) {
                var item = data;
                page.set('value', item);
                self._version = item.version;

                // 参集体制所属
                if (item.municipalityCd === config.municInfo.cityMunicCd && item.dpDeptCd) {
                    self.municipalitySelector.set('value', item.dpDeptCd);
                } else {
                    self.municipalitySelector.set('value', item.municipalityCd);
                }

                // 市本部等設置時利用可
                if (item.osakaCityFlg === '1') {
                    self.osakaCityFlg.set('value', 'on');
                }
    
                // 管理者以外はチェックを外せないように非活性
                // var role = UserInfo.getRoleCd();
                // if (!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.XXMGR_USER)) {
                //     self.osakaCityFlg.set('disabled', true);
                // }

                // 本部設置
                if (item.responseHqFlg === '1') {
                    self.responseHqFlg.set('value', 'on');
                }
                if (item.alertHqFlg === '1') {
                    self.alertHqFlg.set('value', 'on');
                }
                if (item.otherHqFlg === '1') {
                    self.otherHqFlg.set('value', 'on');
                }

                // 初期定義、職員参集のチャットグループは削除不可（ボタン非表示）
                domStyle.set(self.deleteButtonSpan, 'display', 
                    (item.chatGroupType === '1' || item.chatGroupType === '3') ? 'none' : '');
                // 使用区分により所属と本部設置を入力可 or 不可とする
                self.setDisasterPrevention(item.chatGroupUseType);
            });
            // 訓練モードの場合、削除・更新ボタンを非表示とする
            if(UserInfo.isTrainingFlg()){
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
                domStyle.set(this.submitButton.domNode, 'display', 'none');
            }
        },

        /**
         * チャットグループを更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.chatGroupId = this._chatGroupId;
                value.version = this._version;
                if (value.chatGroupUseType === '1') { // 災害時
                    if (this.municipalitySelector.get('value').substr(0,1) === 'D') {
                        value.municipalityCd = this.getDpInfo(this.municipalitySelector.get('value'), 'parentId');
                    }
                    value.dpDeptCd = this.getDpInfo(this.municipalitySelector.get('value'), 'type');
                    // チェックボックスがdisableの場合があるため明示的にセットする
                    // if (this.osakaCityFlg.checked) {
                    //     value.osakaCityFlg = ['on'];
                    // } else {
                    //     value.osakaCityFlg = [];
                    // }
                } else if (value.chatGroupUseType === '2') { // 常時
                    value.municipalityCd = '';
                    value.dpDeptCd = '';
                    value.osakaCityFlg = ['on']; // デフォルトのチェックありをセットしておく
                    value.responseHqFlg = [];
                    value.alertHqFlg = [];
                    value.otherHqFlg = [];
                }
                this.emit('update', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        onDelete:function(){
            console.debug('[削除]ボタンがクリックされました。');
            var value = this.form.get('value');
            value.chatGroupId = this._chatGroupId;
            value.version = this._version;
            this.emit('remove', {value: value});
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();

            this.initForm(object);
            this.chatGroupId.innerHTML = this._chatGroupId;

        },

        /**
         * 市本部等設置時利用可のtips
         */
         setAvailableTips: function() {
            // 共有先
            var message = '・チェック：所属で本部が未設置の場合でも、徳島県で本部が設置されると利用可能になります。<br>' + 
                            '・未チェック：所属で本部が設置された場合のみ、利用可能になります。';
            var tooltip = new TooltipDialog({
                id: 'availableTipsDetail',
                style: 'width: 300px; height:100px',
                content: '<p>' + message + '</p>'
            });
            var label = this.availableTip;
            this.own(tooltip);
            on(label, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: tooltip,
                    around: label
                });
            }));
            on(label, 'mouseleave', function() {
                popup.close(tooltip);
            });
        }

    });
});
