/**
 * 観測地域モデル
 * @module app/model/ObservationRegion
 */
define([
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'value',
        data: [
            {
                'value': '360000',
                'label': '徳島県全域',
                'selected': true,
                'regionFlg': ''
            },
            {
                'value': '',
                'label': '------地域選択------',
                'disabled': 'true',
                'regionFlg': '1'
            },
            {
                'value': '360001',
                'label': '東部',
                'regionFlg': '1'
            },
            {
                'value': '360002',
                'label': '西部',
                'regionFlg': '1'
            },
            {
                'value': '360003',
                'label': '南部',
                'regionFlg': '1'
            }
        ]
    });
});
